<template>
	<div class="content">
		<img class="bg" src="https://pzdsoss.pzds.com/h5/mm_bg/gg_bg.png" alt="" />
		<div class="name" v-if="name">盼之-{{ name }}</div>
		<div class="name" v-else style="transform: translateX(-1.5rem);width: 4.48rem">盼之代售app官方账号</div>
		<div class="page-container">
			<div class="input_box">
				<div class="input">
					<van-field v-model="action.phone" type="number" placeholder="请输入注册手机号" />
				</div>
				<div class="input">
					<van-field v-model="action.smsCode" type="text" placeholder="请输入验证码">
						<template #button>
							<div v-if="isShow" class="code" @click="getCheck">获取验证码</div>
							<div v-else class="code">{{ count }}s</div>
						</template>
					</van-field>
				</div>
				<img class="btn" src="../assets/img/btn.png" @click="login" />
			</div>
			<div class="textBox dis-flex flex-y-start">
				<img v-if="!radio" src="../assets/img/radio.png" alt="" @click="radio = true" />
				<img v-else src="../assets/img/radio_a.png" alt="" @click="radio = false" />
				<div>
					未注册手机验证后自动创建盼之账户 。点击注册即表示您已阅读并同意
					<span @click="$router.push('/readme')">《盼之代售用户协议》</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const name = {
	// xiaohang: '小杭',
	// yuliang: '于凉',
	ajing: '阿景',
	hujing: '虎鲸',
	xigua: '西瓜',
	// pzdsapp:'', // pzdsapp 名字不一样
	// huaming: '花名',
	// shiyou: '十又',
	// dongyang: '冬阳',
	// xiaohuache: '小花车',
	// awei: '阿伟',
	// aawei: '阿威',
	// shequn66: '社群六六',
};
import testApi from '@/api/test';
import { showToast } from 'vant';
export default {
	props: {
		code: {
			type: String,
		},
	},
	computed: {
		name() {
			return name[this.code];
		},
	},
	data() {
		return {
			action: {},
			radio: false,
			isShow: true,
			count: 60,
			times: null,
		};
	},
	methods: {
		login() {
			if (!this.action.phone) return showToast('请输入手机号');
			if (!this.action.smsCode) return showToast('请输入验证码');
			this.$emit('login', this.action);
		},
		getCheck() {
			if (!this.action.phone) return showToast('请输入手机号');
			this.$emit('getCheck', this.action.phone);
			testApi.check({ action: { phone: this.action.phone, smsTemplate: 'LOGIN' } }).then(res => {
				if (res.code == 'SUCCESS') {
					showToast('发送成功');
					this.verification();
				} else {
					showToast(res.info);
				}
			});
		},
		verification() {
			this.isShow = false; // 倒计时
			this.count = 60; // 赋值60秒
			this.times = setInterval(() => {
				this.count--;
				if (this.count <= 0) {
					this.isShow = true;
					clearInterval(this.times);
				}
			}, 1000);
		},
	},
};
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	position: relative;
	overflow: hidden;
	.bg {
		width: 100%;
		height: auto;
	}
	.name {
		position: absolute;
		top: 46vw;
		left: 70vw;
		border-radius: 0.66rem;
		line-height: 0.66rem;
		padding-left: 0.25rem;
		box-sizing: border-box;
		font-size: 0.4rem;
		color: #fff;
		width: 3.48rem;
		height: 0.66rem;
		background: linear-gradient(to right, rgba(255, 203, 2, 1), rgba(255, 203, 2, 0));
	}
	.page-container {
		position: absolute;
		bottom: 0.61rem;
		left: 0;
		right: 0;
		padding: 0 0.56rem;
		.input_box {
			background: url('../assets/img/input_area.png') no-repeat;
			background-size: contain;
			padding: 0.67rem 0.4rem;
			box-sizing: border-box;
			.input {
				height: 1.3rem;
				background: url('../assets/img/input_item.png') no-repeat;
				background-size: contain;
				border-radius: 1.2rem;
				margin-bottom: 0.56rem;
				.code {
					font-weight: 600;
					color: #ffffff;
					font-size: 0.37rem;
				}
			}
			.btn {
				height: 1.2rem;
				border-radius: 0.4rem;
				opacity: 1;
				text-align: center;
				line-height: 1.2rem;
				font-weight: 600;
				color: #333333;
				font-size: 0.37rem;
			}
			/deep/.van-cell {
				height: 1.3rem;
				background-color: transparent;
				display: flex;
				align-items: center;
				.van-field__control {
					color: #fff !important;
				}
			}
			/deep/.van-field__control {
				font-size: 0.37rem;
				font-weight: 300;
			}
			::-webkit-input-placeholder {
				color: #843194;
				font-weight: 300;
			}
		}
		.textBox {
			margin: 0.27rem 0 0 0;
			font-weight: 300;
			color: #f1b24a;
			font-size: 0.3rem;
			span {
				color: #fff;
			}
			img {
				width: 0.32rem;
				height: 0.32rem;
				margin-right: 0.13rem;
			}
		}
	}
}
</style>
